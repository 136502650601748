import { useEffect } from 'react';

import { PAGE_WP_TYPE } from '../contentTypes/page';
import { returnTemplate } from '../lib/_client';
import { returnStaticProps } from '../lib/_helpers';
import { report } from '../lib/sentry';
import SectionBuilder from '../templates/SectionBuilder';

export default function Template(props) {
  useEffect(() => {
    report('PageNotFound', 'Requested page was not found!', { '404_BOUNDARY': true });
  }, []);

  return returnTemplate(props, SectionBuilder);
}

export async function getStaticProps(props) {
  return await returnStaticProps({
    ...props,
    slug: '404-2',
    type: PAGE_WP_TYPE,
  });
}
