import * as Sentry from '@sentry/nextjs';

export function report(
  name: string,
  description: string,
  tags?: Record<string, boolean>
) {
  if (process.env.NEXT_PUBLIC_ENABLE_SENTRY !== 'true') {
    return;
  }

  const error = new Error(description);
  error.name = name;

  Sentry.captureException(error, {
    tags: {
      ...tags,
      pathname: window.location.pathname,
    },
  });
}
